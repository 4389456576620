body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Epilogue", sans-serif !important;


  /* font-family: 'Satisfy', cursive; */
}
/* :where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-dark
  .ant-menu-item-selected {
  background-color: "none";
} */
.ant-menu-item-selected {
  background-color: "none";
}
.ant-menu-submenu-selected:hover {
  background-color: "none";
}
