body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Epilogue", sans-serif !important;


  /* font-family: 'Satisfy', cursive; */
}
.ant-typography{
  font-family: "Epilogue", sans-serif !important;

  
}