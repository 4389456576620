.home-background {
    position: relative; 
    width: 100%; 
    height: 70vh; 
    background-image: url('../../Assets/images/handsImage.jpg'); 
    background-size: cover; 
    background-position: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .card-container {
    width: 24%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 429px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
  }
  
  .carouselParent  .slick-list .slick-slide .ant-image-img{
    object-fit: cover !important;
  }

  
  .card-container:hover img {
    filter: brightness(0) invert(1); /* Invert colors of SVG on hover */
    transform: scale(1.05); /* Zoom effect on hover */
  }
  
  .card-container img {
    transition: filter 0.3s ease, transform 0.3s ease; /* Apply transitions to both filter and transform */
  }


  
  
  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-25px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes shine {
    0%, 100% {
      filter: brightness(50%);
    }
    50% {
      filter: brightness(150%);
    }
  }
  
  .shining-image {
    height: 80px;
    width: 80px;
    animation: shine 2s infinite;
  }
  